import React from 'react'
import './header.css'

export const Header = () => {
    return (
        <div className='header'>
            <div className='wrapper header__content'>
                <div className='header__content-logo'>GAME<span style={{color: '#FFF'}}>PLAY</span></div>
            </div>
        </div>
    );
}