import React from 'react'
import './present-block.css'

export const PresentBlock = () => {
    return (
        <div className='container'>
            <img className='ellipse-yellow' src={require('../../../assets/images/ellipse-blue.png')} alt='bg'/>
            <div className='bg'>
                <div className='wrapper'>
                    <div className='present-block'>
                        <div className='present-block__wrapper'>
                            <div className='present-block__image-block'>
                                <img className='present-block__image-block-img' src={require('../../../assets/images/girl.png')} alt='image'/>
                            </div>

                            <div className='present-block__info'>
                                <div className='present-block__info-title'>СКИДКИ!</div>
                                <div className='present-block__info-subtitle'>В НАШЕМ БОТЕ</div>
                                <div className='present-block__info-description'>В нашем телеграм-боте вы найдете полный ассортимент цифровых товаров, предлагаемых GamePlay.</div>
                                <div className='present-block__info-subdescription'>От захватывающих игр до полезного программного обеспечения - у нас есть все, чтобы сделать ваш цифровой опыт максимально разнообразным и увлекательным.</div>
                                <div className='present-block__info-buttons'>
                                    <a href="https://t.me/gpstore_robot" className='products-block__items-item-buttons-tg present-block__info-buttons-tg'>
                                        <img src={require('../../../assets/icons/tg.svg').default} className='products-block__items-item-buttons-tg-img' alt='image'/>
                                        Перейти в Telegram бот
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}