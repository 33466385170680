// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    padding-top: 60px;
}

.home__uptitle {
    color: var(--yellow-color);
    font-size: 14px;
    text-transform: uppercase;
}

.home__title {
    font-size: 66px;
    font-weight: 700;
    line-height: 1.1;
}

.home__subtitle {
    margin-top: 20px;
    font-size: 14px;
    color: var(--white-color);
    opacity: 60%;
}


@media (max-width: 1024px) {
    .home {
        padding-top: 0px;
    }
    .home__title {
        font-size: 46px;
    }
}

@media (max-width: 768px) {
    .home {
        padding-top: 30px;
    }
    .home__uptitle {
        text-align: center;
    }
    
    .home__title {
        text-align: center;
    }
    
    .home__subtitle {
        text-align: center;
    }
}

@media (max-width: 375px) {
    .home__uptitle {
        margin-bottom: 10px;
    }
    .home__title {
        font-size: 36px;
    }
    .home__subtitle {
        font-size: 13px;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/home/home.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,YAAY;AAChB;;;AAGA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".home {\n    padding-top: 60px;\n}\n\n.home__uptitle {\n    color: var(--yellow-color);\n    font-size: 14px;\n    text-transform: uppercase;\n}\n\n.home__title {\n    font-size: 66px;\n    font-weight: 700;\n    line-height: 1.1;\n}\n\n.home__subtitle {\n    margin-top: 20px;\n    font-size: 14px;\n    color: var(--white-color);\n    opacity: 60%;\n}\n\n\n@media (max-width: 1024px) {\n    .home {\n        padding-top: 0px;\n    }\n    .home__title {\n        font-size: 46px;\n    }\n}\n\n@media (max-width: 768px) {\n    .home {\n        padding-top: 30px;\n    }\n    .home__uptitle {\n        text-align: center;\n    }\n    \n    .home__title {\n        text-align: center;\n    }\n    \n    .home__subtitle {\n        text-align: center;\n    }\n}\n\n@media (max-width: 375px) {\n    .home__uptitle {\n        margin-bottom: 10px;\n    }\n    .home__title {\n        font-size: 36px;\n    }\n    .home__subtitle {\n        font-size: 13px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
