import React from 'react'
import './home.css'
import { MainBlock } from '../../components/home/main-block/main-block';
import { AboutBlock } from '../../components/home/about-block/about-block';
import { CardsBlock } from '../../components/home/cards-block/cards-block';
import { ProductsBlock } from '../../components/home/products-block/products-block';
import { PresentBlock } from '../../components/home/present-block/present-block';

export const Home = () => {
    return (
        <div className='home'>
            <MainBlock />
            <AboutBlock />
            <CardsBlock />
            <ProductsBlock />
            <PresentBlock />
        </div>
    );
}