import React from 'react'
import './main-block.css'

export const MainBlock = () => {
    return (
        <div className='container'>
        <img className='ellipse-blue' src={require('../../../assets/images/ellipse-blue.png')} alt='bg'/>
        <div className='bg'>
            <div className='wrapper'>
                <div className='main-block'>
                    <div className='main-block__info'>
                    <div className='home__uptitle'>добро пожаловать в GAMEPLAY</div>
                    <div className='home__title'>Играй с</div>
                    <div className='home__title'>GAMEPLAY!</div>
                    <div className='home__subtitle'>
                    Это место, где геймеры находят все, что им нужно для цифрового веселья! Мы здесь, чтобы сделать вашу жизнь виртуально интереснее, предоставляя лучшие игры, программы и всякие цифровые плюшки. Нам не важно, вы ли новичок или опытный геймер, у нас есть что-то для каждого.
                    </div>

                    <div className='main-block__buttons'>
                        <a href='https://t.me/gpstore_robot' className='primary-btn main-block__buttons-btn'>Перейти в Telegram-бот</a>
                        <a href='#products' className='outline-btn'>Перейти к товарам</a>
                    </div>
                    </div>

                    <div className='main-block__image'>
                        <img className='main-block__image-item' src={require('../../../assets/images/gamepad.png')} alt='image-1'/>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}