// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100%;
    height: 60px;
    /* background-color: #00000009;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); */
    display: flex;
    align-items: center;
    z-index: 1;
}

.header__content {
    width: 100%;
}

.header__content-logo {
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 0.7px;
    color: var(--yellow-color);
    font-family: 'Parimatch';
}

@media (max-width: 425px) {
    .header__content-logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ;;kCAE8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,0BAA0B;IAC1B,wBAAwB;AAC5B;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,uBAAuB;IAC3B;AACJ","sourcesContent":[".header {\n    width: 100%;\n    height: 60px;\n    /* background-color: #00000009;\n    -webkit-backdrop-filter: blur(10px);\n    backdrop-filter: blur(10px); */\n    display: flex;\n    align-items: center;\n    z-index: 1;\n}\n\n.header__content {\n    width: 100%;\n}\n\n.header__content-logo {\n    font-size: 45px;\n    font-weight: 700;\n    letter-spacing: 0.7px;\n    color: var(--yellow-color);\n    font-family: 'Parimatch';\n}\n\n@media (max-width: 425px) {\n    .header__content-logo {\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
