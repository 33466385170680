import React from 'react'
import './about-block.css'

export const AboutBlock = () => {
    return (
        <div className='container'>
            <img className='ellipse-yellow' src={require('../../../assets/images/ellipse-green.png')} alt='bg'/>
            <img className='ellipse-blue-second' src={require('../../../assets/images/ellipse-blue.png')} alt='bg'/>
            <div className='bg'>
                <div className='wrapper'>
                    <div className='about-block'>
                        <div className='about-block__image'>
                            <img className='main-block__image-item' src={require('../../../assets/images/image-2.png')} alt='image-1'/>
                        </div>

                        <div className='about-block__info'>
                            <div className='home__uptitle'>о нас</div>
                            <div className='home__title'>Что такое</div>
                            <div className='home__title'>GAMEPLAY ?</div>
                            <div className='home__subtitle'>
                            Забудьте о сложностях, наша команда упростила процесс заказа и доставки цифровых товаров. Мгновенные покупки, безопасные транзакции и круглосуточная поддержка - все, чтобы вы могли сразу окунуться в мир цифровых развлечений. Присоединяйтесь к нам, откройте для себя новые игры и цифровые приключения с GamePlay!
                            </div>

                            <div className='about-block__info-items'>
                                <div className='about-block__info-items-item'>
                                    <div className='about-block__info-items-item-bgico'>
                                        <img className='about-block__info-items-item-bgico-icon' src={require('../../../assets/icons/gamepad.svg').default} alt='icon'/>
                                    </div> 

                                    <div className='about-block__info-items-item-text'>
                                        <div className='about-block__info-items-item-text-title'>Выгодные цены</div>
                                        <div  className='about-block__info-items-item-text-subtitle'>
                                        GamePlay радует своих клиентов выгодными ценами на широкий ассортимент цифровых товаров. У нас вы найдете отличные предложения, которые делают ваше цифровое развлечение еще более доступным и приятным.
                                        </div>
                                    </div>
                                </div>

                                <div className='about-block__info-items-item'>
                                    <div className='about-block__info-items-item-bgico'>
                                        <img className='about-block__info-items-item-bgico-icon' src={require('../../../assets/icons/controller.svg').default} alt='icon'/>
                                    </div> 

                                    <div className='about-block__info-items-item-text'>
                                        <div className='about-block__info-items-item-text-title'>Быстрая выдача</div>
                                        <div  className='about-block__info-items-item-text-subtitle'>
                                        GamePlay гарантирует моментальную выдачу цифровых товаров, обеспечивая немедленный доступ к вашим любимым играм и программам. Наша система оперативной доставки позволяет вам начать цифровое веселье сразу после покупки, без лишних задержек.
                                        </div>
                                    </div>
                                </div>

                                <div className='about-block__info-items-item'>
                                    <div className='about-block__info-items-item-bgico'>
                                        <img className='about-block__info-items-item-bgico-icon' src={require('../../../assets/icons/pacman.svg').default} alt='icon'/>
                                    </div> 

                                    <div className='about-block__info-items-item-text'>
                                        <div className='about-block__info-items-item-text-title'>Гарантии</div>
                                        <div  className='about-block__info-items-item-text-subtitle'>GamePlay обеспечивает надежные гарантии, подкрепленные более чем 1000 успешными сделками. Наш высокий рейтинг на популярных маркетплейсах цифровых товаров - это подтверждение довольных клиентов, которые выбирают нас для беззаботных покупок и надежных сделок.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}