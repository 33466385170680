import React from 'react'
import './products-block.css'

export const ProductsBlockItem = ({items}) => {
    return (
        <div className='products-block__items'>
        {items.map((item, i) => (
            <div key={i} className='products-block__items-item'>
                <div className='products-block__items-item-images'>
                    <img src={require(`../../../assets/images/products/${item.image}`)} className='products-block__items-item-main-image' alt='image'/>
                    {/* <img src={item.logo} className='products-block__items-item-secondary-image' alt='image'/> */}
                    <div className='products-block__items-item-info'>
                        <div className='products-block__items-item-info-title'>{item.name}</div>
                        <div className='products-block__items-item-info-subtitle'>{item.tags[0]}</div>
                    </div>
                </div>
                <div>
                    <div className='products-block__items-item-review'>
                        <div className='products-block__items-item-review-star'>
                            <img src={require('../../../assets/icons/star.svg').default} className='products-block__items-item-review-star-img' alt='star'/>
                            <div>
                                <div className='products-block__items-item-review-star-number'>{item.star}</div>
                                <div className='products-block__items-item-review-star-review'>{item.countReviews} заказов</div>
                            </div>
                        </div>
                        {/* <div className='products-block__items-item-review-downloads'>
                            <div className='products-block__items-item-review-downloads-count'>{item.downloads}+</div>
                            <div className='products-block__items-item-review-downloads-text'>Downloads</div>
                        </div> */}
                    </div>
                    <div className='products-block__items-item-buttons'>
                        {item.wb === '' ? null : <a href={item.wb} className='products-block__items-item-buttons-wb'>
                            <img src={require('../../../assets/icons/wb.svg').default} className='products-block__items-item-buttons-wb-img' alt='image'/>
                        </a>}
                        <a href={item.tg} className='products-block__items-item-buttons-tg'>
                            <img src={require('../../../assets/icons/tg.svg').default} className='products-block__items-item-buttons-tg-img' alt='image'/>
                            Перейти в телеграм бот
                        </a>
                    </div>
                </div>
            </div>
        ))}
        </div>
    );
}

export const ProductsBlock = () => {
    return (
        <div id="products" className='container'>
            <img className='ellipse-blue' src={require('../../../assets/images/ellipse-blue.png')} alt='bg'/>
            <div className='bg'>
                <div className='wrapper'>
                    <div className='products-block'>
                        <div className='products-block__header'>
                            <div className='cards-block__title products-block__header-title'>Товары</div>
                            <a href='https://t.me/gpstore_robot' className='primary-btn'>Смотреть все</a>
                        </div>

                        <ProductsBlockItem items={[
                            {
                                image: 'xbox-game-pass.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Xbox Game Pass Ultimate',
                                tags: [
                                    'Xbox'
                                ],
                                star: '5.0',
                                countReviews: '500+',
                                downloads: '120M',
                                wb: 'https',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'ps-plus.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'PS PLUS',
                                tags: [
                                    'Play Station'
                                ],
                                star: '5.0',
                                countReviews: '700+',
                                downloads: '120M',
                                wb: 'https',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'robux.webp',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Roblox (робуксы)',
                                tags: [
                                    'Игры'
                                ],
                                star: '5.0',
                                countReviews: '300+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'v-bucks.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Fortnite V-Bucks',
                                tags: [
                                    'Игры'
                                ],
                                star: '5.0',
                                countReviews: '250+',
                                downloads: '120M',
                                wb: 'https',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'gems.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Brawl Stars (гемы)',
                                tags: [
                                    'Мобильные игры'
                                ],
                                star: '5.0',
                                countReviews: '150+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'gems-clash.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Clash of Clans (гемы)',
                                tags: [
                                    'Мобильные игры'
                                ],
                                star: '5.0',
                                countReviews: '200+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'clash-royal.webp',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Clash Royale (гемы)',
                                tags: [
                                    'Мобильные игры'
                                ],
                                star: '5.0',
                                countReviews: '350+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'crystals.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Mobile Legends (кристалы)',
                                tags: [
                                    'Игры'
                                ],
                                star: '5.0',
                                countReviews: '200+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'gold.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Wot Blitz (золото)',
                                tags: [
                                    'Игры'
                                ],
                                star: '5.0',
                                countReviews: '400+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'standoff.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Standoff 2 (голда)',
                                tags: [
                                    'Игры'
                                ],
                                star: '5.0',
                                countReviews: '350+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'stumble-gems.svg',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Stumble Guys (гемы)',
                                tags: [
                                    'Мобильные игры'
                                ],
                                star: '5.0',
                                countReviews: '100+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                            {
                                image: 'uc.png',
                                logo: 'https://cdn1.epicgames.com/spt-assets/be1eef63158a45c889777213954bd1cf/naheulbeuks-dungeon-master-1gpua.jpg?h=480&quality=medium&resize=1&w=360',
                                name: 'Pubg Mobile (UC)',
                                tags: [
                                    'Мобильные игры'
                                ],
                                star: '5.0',
                                countReviews: '500+',
                                downloads: '120M',
                                wb: '',
                                tg: 'https://t.me/gpstore_robot',
                            },
                        ]} />
                    </div>
                </div>
            </div>
        </div>
    );
}