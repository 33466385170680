import React from 'react'
import './cards-block.css'

export const CardsBlock = () => {
    return (
        <div className='container'>
            <img className='ellipse-yellow' src={require('../../../assets/images/ellipse-yellow.png')} alt='bg'/>
            <img className='ellipse-blue-second' src={require('../../../assets/images/ellipse-blue.png')} alt='bg'/>
            <div className='bg'>
                <div className='wrapper'>
                    <div className='cards-block'>
                        <div className='cards-block__title'>Платформы</div>
                        <div className='cards-block__cards'>
                            <div className='cards-block__cards-item'>
                                <img 
                                    src={require('../../../assets/images/xbox.png')}
                                    className='cards-block__cards-item-img'
                                    alt='image'
                                    />
                                <div className='cards-block__cards-item-info'>
                                    <div className='cards-block__cards-item-info-title'>XBOX</div>
                                    {/* <div className='cards-block__cards-item-info-subtitle'>subtitle</div> */}
                                </div>
                            </div>
                            <div className='cards-block__cards-item'>
                                <img 
                                    src={require('../../../assets/images/ps.png')}
                                    className='cards-block__cards-item-img'
                                    alt='image'
                                    />
                                <div className='cards-block__cards-item-info'>
                                    <div className='cards-block__cards-item-info-title'>PlayStation</div>
                                    {/* <div className='cards-block__cards-item-info-subtitle'>subtitle</div> */}
                                </div>
                            </div>
                            <div className='cards-block__cards-item'>
                                <img 
                                    src={require('../../../assets/images/epic.png')} 
                                    className='cards-block__cards-item-img'
                                    alt='image'
                                    />
                                <div className='cards-block__cards-item-info'>
                                    <div className='cards-block__cards-item-info-title'>Epic Games</div>
                                    {/* <div className='cards-block__cards-item-info-subtitle'>subtitle</div> */}
                                </div>
                            </div>
                            <div className='cards-block__cards-item'>
                                <img 
                                    src={require('../../../assets/images/steam.png')} 
                                    className='cards-block__cards-item-img'
                                    alt='image'
                                    />
                                <div className='cards-block__cards-item-info'>
                                    <div className='cards-block__cards-item-info-title'>Steam</div>
                                    {/* <div className='cards-block__cards-item-info-subtitle'>subtitle</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}